<!--
// nuxt-ui/components/pages/region-draft/DraftPlayers.vue
-->
<script setup lang="ts">
import type { AnyDraftPlayer } from "~/src/draft/domain/models/DraftPlayer";
import DraftPlayerCard from "~/nuxt-ui/components/pages/region-draft/DraftPlayerCard.vue";
import BaseVideoModal from "~/nuxt-ui/components/base/BaseVideoModal.vue";
import CardNoDataMessage from "~/nuxt-ui/components/Shared/CardNoDataMessage.vue";

type Props = {
  players: AnyDraftPlayer[];
  isReserves?: boolean;
};
const props = defineProps<Props>();
const modalActive = ref<boolean>(false);
const modalVideoId = ref<string>("");
const player = ref<AnyDraftPlayer>();

const openModalVideo = (playerData: AnyDraftPlayer, type: "profile" | "match"): void => {
  player.value = playerData;
  if (type === "profile") {
    modalVideoId.value = playerData.profileYtVideoId;
  } else if (type === "match" && !!playerData.matchYtVideoId) {
    modalVideoId.value = playerData.matchYtVideoId;
  }
  modalActive.value = true;
};
</script>

<template>
  <div class="flex flex-wrap gap-2 lg:gap-4 lg:mt-7 lg:px-6 lg:justify-center">
    <card-no-data-message
      v-if="!players.length"
      :message="$t('shared.errorMessage.noData')"
    ></card-no-data-message>

    <draft-player-card
      v-for="(player, i) in players"
      :player="player"
      :loadingLazy="i > 4"
      @open-profile-video="e => openModalVideo(e, 'profile')"
      @open-match-video="e => openModalVideo(e, 'match')"
      :is-reserves="isReserves"
    ></draft-player-card>
  </div>

  <base-video-modal modal-id="modal-video-draft" :video-id="modalVideoId" v-model="modalActive">
    <template #header>
      <div v-if="player" class="py-2 px-4 kql-player-name">
        <p class="font-bold">
          <span v-if="player.draftJersey">#{{ player.draftJersey }}</span>
          <span v-else>#</span>
          <span class="ml-2">{{ player.name }}</span>
        </p>
        <p v-if="player.role" class="text-gray-400 text-xs lg:text-sm">
          {{ $t(`draft.player.position.${player.role}`) }}
        </p>
      </div>
    </template>

    <template #footer>
      <div v-if="player && player.macros" class="flex flex-wrap gap-1 py-2 px-4">
        <div
          v-for="(value, key) in player.macros"
          class="rounded-lg px-1 py-3 flex-1 text-center"
          :class="key === 'average' ? 'bg-primary text-black' : 'bg-gray-800'"
        >
          <p class="text-[10px] kql-stats-key" :class="key === 'average' ? 'text-black' : 'text-gray-400'">
            {{
              key === "passing" && player.role === "PT"
                ? $t(`draft.player.goalkeeper.${key}`)
                : $t(`draft.player.${key}`)
            }}
          </p>
          <p class="font-bold">{{ value }}</p>
        </div>
      </div>
    </template>
  </base-video-modal>
</template>

<style scoped>
.kql-player-name {
  @apply transition-all ease-in-out duration-500;
}
</style>
