<!--
// nuxt-ui/components/pages/region-draft/DraftPlayerFilters.vue
-->
<script setup lang="ts">
import BaseRangeSlider from "~/nuxt-ui/components/base/BaseRangeSlider.vue";
import type { MacroFilters, MacroFilter } from "~/src/draft/domain/models/Filters";

const route = useRoute();

const playerPositions = [
  { positionId: "DF", position: "draft.player.position.DF" },
  { positionId: "MC", position: "draft.player.position.MC" },
  { positionId: "DL", position: "draft.player.position.DL" },
];

const fullPlayerPositions = [
  { positionId: "DF", position: "draft.player.position.DF" },
  { positionId: "MC", position: "draft.player.position.MC" },
  { positionId: "DL", position: "draft.player.position.DL" },
  { positionId: "PT", position: "draft.player.position.PT" },
];

const playerFootOptions = [
  { footId: "left", foot: "draft.player.foot.left" },
  { footId: "right", foot: "draft.player.foot.right" },
];

type Props = {
  playerRole?: string | null;
  macros?: MacroFilters;
};

const props = defineProps<Props>();

const showMoreFilters = ref(false);
const showMobileFilters = ref(false);
const isMobile = ref(false);

const playerPosition = defineModel<string | null>("playerPosition");
const playerFoot = defineModel<string | null>("playerFoot");
const height = defineModel<MacroFilter>("height", { required: true });

const toggleShowMoreFilters = () => {
  showMoreFilters.value = !showMoreFilters.value;
};

const toggleShowMobileFilters = () => {
  showMobileFilters.value = !showMobileFilters.value;
};

onMounted(() => {
  isMobile.value = window.innerWidth < 1200;
  window.addEventListener("resize", () => {
    isMobile.value = window.innerWidth < 1200;
  });
});

const heightMinComp = computed({
  get() {
    return height.value?.min || 0;
  },
  set(newValue) {
    height.value.min = Number(newValue);
  },
});

const heightMaxComp = computed({
  get() {
    return height.value?.max || 100;
  },
  set(newValue) {
    height.value.max = Number(newValue);
  },
});

const averageMinComp = computed({
  get() {
    return props.macros.average?.min || 0;
  },
  set(newValue) {
    props.macros.average.min = Number(newValue);
  },
});

const averageMaxComp = computed({
  get() {
    return props.macros.average?.max || 100;
  },
  set(newValue) {
    props.macros.average.max = Number(newValue);
  },
});
</script>

<template>
  <div class="mb-8 lg:mb-0">
    <button
      class="bg-gray-800 text-gray-400 rounded-lg p-4 text-xs lg:text-sm h-fit w-full lg:hidden block"
      @click="toggleShowMobileFilters"
    >
      {{ $t("draft.player.filters.showMore") }}
      <!-- Arrow Filter -->
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="13"
        height="13"
        viewBox="0 0 13 13"
        fill="none"
        class="inline-block"
        :class="showMobileFilters ? 'rotate-180' : ''"
      >
        <path
          d="M6.50875 9.07598C6.31781 9.07594 6.1347 9.00231 5.99971 8.8713L3.11971 6.07702C3.05094 6.01258 2.99609 5.93549 2.95836 5.85027C2.92062 5.76504 2.90076 5.67337 2.89993 5.58062C2.8991 5.48786 2.91732 5.39587 2.95352 5.31002C2.98972 5.22417 3.04318 5.14617 3.11079 5.08058C3.17839 5.01499 3.25878 4.96312 3.34726 4.928C3.43575 4.89287 3.53056 4.8752 3.62616 4.876C3.72176 4.87681 3.81624 4.89608 3.90408 4.93269C3.99192 4.9693 4.07137 5.02252 4.13779 5.08924L6.50875 7.38963L8.87971 5.08924C9.0155 4.96199 9.19738 4.89158 9.38616 4.89317C9.57494 4.89476 9.75553 4.96823 9.88902 5.09775C10.0225 5.22727 10.0982 5.40248 10.0999 5.58564C10.1015 5.76881 10.0289 5.94527 9.89779 6.07702L7.01779 8.8713C6.88279 9.00231 6.69969 9.07594 6.50875 9.07598Z"
          fill="#686868"
        />
      </svg>
    </button>
    <div v-if="!isMobile || (isMobile && showMobileFilters)" class="mt-4">
      <div>
        <div class="flex lg:mb-7 lg:gap-4 flex-col lg:flex-row gap-4">
          <div class="kql-player-filters">
            <p class="text-xs lg:text-sm lg:mb-4">{{ $t("draft.player.dominantFoot") }}</p>
            <div class="kql-player-filter-wrapper-options">
              <button
                v-for="(playerDominantFoot, index) in playerFootOptions"
                :key="index"
                :class="[
                  'kql-player-filter',
                  {
                    '!bg-primary !text-black': playerDominantFoot.footId === playerFoot,
                  },
                ]"
                @click="playerFoot = playerDominantFoot.footId"
              >
                {{ $t(playerDominantFoot.foot) }}
              </button>
            </div>
          </div>

          <div v-if="playerRole !== 'goalkeeper'" class="kql-player-filters">
            <p class="text-xs lg:text-sm lg:mb-4">{{ $t("draft.player.position") }}</p>
            <div class="kql-player-filter-wrapper-options">
              <button
                v-for="(position, index) in playerPositions"
                :key="index"
                :class="[
                  'kql-player-filter',
                  {
                    '!bg-primary !text-black': position.positionId === playerPosition,
                  },
                ]"
                @click="playerPosition = position.positionId"
              >
                {{ $t(position.position) }}
              </button>
            </div>
          </div>

          <base-range-slider
            id="height"
            v-if="height && heightMaxComp && heightMinComp"
            :title="$t('draft.player.height')"
            v-model:min="heightMinComp"
            v-model:max="heightMaxComp"
            :from="height.from"
            :to="height.to"
          ></base-range-slider>

          <base-range-slider
            v-if="macros && macros.average"
            id="overall"
            :title="$t('draft.player.average')"
            v-model:min="averageMinComp"
            v-model:max="averageMaxComp"
            :from="macros.average.from"
            :to="macros.average.to"
            class="mb-4 md:mb-0"
          ></base-range-slider>

          <button
            v-if="macros"
            class="bg-gray-800 text-gray-400 rounded-lg px-3 py-2 text-xs lg:text-sm h-fit mt-8 hidden lg:block"
            @click="toggleShowMoreFilters"
          >
            {{ $t("draft.player.filters.showMore") }}
            <!-- Arrow Filter -->
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="13"
              height="13"
              viewBox="0 0 13 13"
              fill="none"
              class="inline-block"
              :class="showMoreFilters ? 'rotate-180' : ''"
            >
              <path
                d="M6.50875 9.07598C6.31781 9.07594 6.1347 9.00231 5.99971 8.8713L3.11971 6.07702C3.05094 6.01258 2.99609 5.93549 2.95836 5.85027C2.92062 5.76504 2.90076 5.67337 2.89993 5.58062C2.8991 5.48786 2.91732 5.39587 2.95352 5.31002C2.98972 5.22417 3.04318 5.14617 3.11079 5.08058C3.17839 5.01499 3.25878 4.96312 3.34726 4.928C3.43575 4.89287 3.53056 4.8752 3.62616 4.876C3.72176 4.87681 3.81624 4.89608 3.90408 4.93269C3.99192 4.9693 4.07137 5.02252 4.13779 5.08924L6.50875 7.38963L8.87971 5.08924C9.0155 4.96199 9.19738 4.89158 9.38616 4.89317C9.57494 4.89476 9.75553 4.96823 9.88902 5.09775C10.0225 5.22727 10.0982 5.40248 10.0999 5.58564C10.1015 5.76881 10.0289 5.94527 9.89779 6.07702L7.01779 8.8713C6.88279 9.00231 6.69969 9.07594 6.50875 9.07598Z"
                fill="#686868"
              />
            </svg>
          </button>
        </div>
        <div
          class="flex flex-wrap lg:mb-7 lg:gap-4 flex-col lg:flex-row gap-4"
          v-if="((!isMobile && showMoreFilters) || isMobile) && macros"
        >
          <base-range-slider
            v-for="(macro, key) in macros"
            :id="key"
            :title="
              playerRole === 'goalkeeper' && key === 'passing'
                ? $t(`draft.player.goalkeeper.${[key]}`)
                : $t(`draft.player.${[key]}`)
            "
            v-model:min="macro.min"
            v-model:max="macro.max"
            :from="macro.from"
            :to="macro.to"
            :class="{ '!hidden': key === 'average' }"
          ></base-range-slider>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.kql-player-filter {
  @apply px-4 py-2 my-1 lg:my-0 text-sm rounded-full lg:rounded-none border-gray-700 border lg:border-t-0 lg:border-b-0;
  @apply lg:border-l lg:border-r bg-gray-900 lg:border-gray-800 text-gray-400 hover:text-black hover:bg-primary w-full lg:w-auto;
}

.kql-player-filter:first-child {
  @apply lg:rounded-s-lg lg:border-l-0;
}

.kql-player-filter:last-child {
  @apply lg:rounded-e-lg lg:border-r-0 lg:rounded-s-none;
}

.kql-player-filter-wrapper-options {
  @apply flex justify-stretch gap-1 lg:gap-0;
}
</style>
