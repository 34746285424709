<!--
// nuxt-ui/components/base/BaseRangeSlider.vue
-->
<script setup lang="ts">
type Props = {
  id: string;
  title: string;
  from?: number;
  to?: number;
};

const props = withDefaults(defineProps<Props>(), {
  from: 0,
  to: 100,
});

const min = defineModel<number>("min", { required: true });
const max = defineModel<number>("max", { required: true });

const minComp = ref<number>(min.value);
const maxComp = ref<number>(max.value);
</script>

<template>
  <div class="range_container">
    <p class="mb-8 text-xs lg:text-sm">{{ title }}</p>
    <div class="sliders_control">
      <input
        :id="`fromSlider-${id}`"
        class="fromSlider"
        type="range"
        v-model="minComp"
        @change="min = Number(minComp)"
        :min="from"
        :max="to"
      />
      <input
        :id="`toSlider-${id}`"
        class="toSlider"
        type="range"
        v-model="maxComp"
        @change="max = Number(maxComp)"
        :min="from"
        :max="to"
      />
    </div>
    <div class="flex justify-between text-xs">
      <div class="text-gray-400 rounded bg-gray-800 p-1">
        {{ minComp }}
      </div>
      <div class="text-gray-400 rounded bg-gray-800 p-1">
        {{ maxComp }}
      </div>
    </div>
  </div>
</template>

<style scoped>
.range_container {
  @apply flex-1 flex flex-col;
}

.sliders_control {
  @apply relative mb-2 min-h-[15px] flex items-center;
}

input[type="range"].fromSlider {
  @apply h-1 z-[1];
}

/*webkit*/
input[type="range"]::-webkit-slider-thumb {
  @apply appearance-none w-5 h-5 bg-primary border border-solid border-primary rounded-full cursor-pointer -mt-2 relative z-[2];
}

input[type="range"].toSlider::-webkit-slider-runnable-track {
  @apply bg-primary h-1;
}

input[type="range"].fromSlider::-webkit-slider-runnable-track {
  @apply bg-gray-800 h-1;
}

input[type="range"] {
  @apply w-full absolute bg-transparent appearance-none !h-1;
}

/*moz*/
input[type="range"].toSlider::-moz-range-progress {
  @apply !bg-primary;
}

input[type="range"].toSlider::-moz-range-track {
  @apply bg-gray-800 h-1;
}

input[type="range"].fromSlider::-moz-range-progress {
  @apply !bg-gray-800;
}

input[type="range"]::-moz-range-thumb {
  @apply appearance-none w-5 h-5 bg-primary border border-solid border-primary rounded-full cursor-pointer;
}
</style>
