// src/draft/domain/services/draftService.ts

import type { AnyDraftPlayer } from "~/src/draft/domain/models/DraftPlayer";
import type { MacroFilters } from "~/src/draft/domain/models/Filters";

interface DraftService {
  createMinMaxMacrosFilters: (players: AnyDraftPlayer[]) => MacroFilters | undefined;
  findMacroMinMax: (macroKey: string, players: AnyDraftPlayer[]) => [number, number];
  findHeightMinMax: (players: AnyDraftPlayer[]) => [number, number];
}

const createMinMaxMacrosFilters = (players: AnyDraftPlayer[]): MacroFilters | undefined => {
  let filters = {} as MacroFilters;
  const [firstPlayer] = players;
  if (firstPlayer) {
    for (const macrosKey in firstPlayer.macros) {
      const [min, max] = findMacroMinMax(macrosKey, players);
      filters[macrosKey] = { min, max };
    }
    return filters;
  }

  return undefined;
};

const findMacroMinMax = (macroKey: string, players: AnyDraftPlayer[]): [number, number] => {
  const valuesArr: number[] = players.map(p => p.macros[macroKey]);
  return findMinMax(valuesArr);
};

const findHeightMinMax = (players: AnyDraftPlayer[]): [number, number] => {
  const valuesArr: number[] = players.map(p => p.height);
  const valuesWithValue = valuesArr.filter(v => !!v);
  return findMinMax(valuesWithValue);
};

const draftService = (): DraftService => ({
  createMinMaxMacrosFilters,
  findMacroMinMax,
  findHeightMinMax,
});

export default draftService;

function findMinMax(values: number[]): [number, number] {
  return [Math.min(...values), Math.max(...values)];
}
