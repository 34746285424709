<!--
// nuxt-ui/components/Shared/draft/DraftYoutubeLogo.vue
-->
<script setup lang="ts"></script>

<template>
  <svg
    class="youtube-logo inline-block mr-2"
    xmlns="http://www.w3.org/2000/svg"
    width="12"
    height="13"
    viewBox="0 0 12 13"
    fill="none"
  >
    <path
      d="M11.27 3.71061C11.2078 3.47659 11.0852 3.26301 10.9146 3.09124C10.7439 2.91948 10.5311 2.79555 10.2975 2.73186C9.4375 2.50061 6 2.50061 6 2.50061C6 2.50061 2.5625 2.50061 1.7025 2.73186C1.46888 2.79555 1.25609 2.91948 1.08542 3.09124C0.914753 3.26301 0.79219 3.47659 0.73 3.71061C0.5 4.57436 0.5 6.37561 0.5 6.37561C0.5 6.37561 0.5 8.17686 0.73 9.04061C0.79219 9.27463 0.914753 9.48821 1.08542 9.65998C1.25609 9.83174 1.46888 9.95567 1.7025 10.0194C2.5625 10.2506 6 10.2506 6 10.2506C6 10.2506 9.4375 10.2506 10.2975 10.0194C10.5311 9.95567 10.7439 9.83174 10.9146 9.65998C11.0852 9.48821 11.2078 9.27463 11.27 9.04061C11.5 8.17686 11.5 6.37561 11.5 6.37561C11.5 6.37561 11.5 4.57436 11.27 3.71061Z"
      fill="#FF0302"
    />
    <path d="M4.87501 8.01125V4.74L7.75001 6.37563L4.87501 8.01125Z" fill="white" />
  </svg>
</template>

<style scoped></style>
