<!--
// nuxt-ui/components/pages/region-draft/DraftRoleFilter.vue
-->
<script setup lang="ts">
const playerRoles = [
  { roleId: "player", role: "draft.position.role.player" },
  { roleId: "goalkeeper", role: "draft.position.role.gk" },
];

type Props = {
  modelValue: string | null;
};

const props = withDefaults(defineProps<Props>(), {
  modelValue: null,
});
const emit = defineEmits(["update:modelValue", "change"]);

const changeRoleFilter = (positionRole: string) => {
  emit("update:modelValue", positionRole);
  emit("change", positionRole);
};
</script>

<template>
  <div class="container-buttons">
    <button
      v-for="(role, index) in playerRoles"
      :key="index"
      :class="[
        'kql-positions-position',
        {
          '!bg-primary !text-black !border-primary': role.roleId === modelValue,
        },
      ]"
      @click="changeRoleFilter(role.roleId)"
    >
      {{ $t(role.role) }}
    </button>
  </div>
</template>

<style scoped>
.container-buttons {
  @apply flex order-1 sm:order-[0] gap-1 sm:gap-0 w-full sm:w-auto;
}
.kql-positions-position {
  @apply px-4 py-2 text-sm rounded-full sm:rounded-none border-gray-700 border sm:border-t-0 sm:border-b-0 sm:border-l;
  @apply sm:border-r bg-gray-900 sm:border-gray-800 text-gray-400 hover:text-black hover:bg-primary w-full sm:w-auto;
  @apply flex-1;
}

.kql-positions-position:first-child {
  @apply sm:rounded-s-lg sm:border-l-0;
}

.kql-positions-position:last-child {
  @apply sm:rounded-e-lg sm:border-r-0;
}
</style>
