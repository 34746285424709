<!--
// nuxt-ui/components/pages/region-draft/DraftPlayerCard.vue
-->
<script setup lang="ts">
import type { AnyDraftPlayer } from "~/src/draft/domain/models/DraftPlayer";
import DraftYoutubeLogo from "~/nuxt-ui/components/Shared/draft/DraftYoutubeLogo.vue";
import PlayerPlaceholder from "~/nuxt-ui/assets/images/kl-player-placeholder.png";
import { setAliasUrl } from "~/src/Shared/utils";

type Props = {
  player: AnyDraftPlayer;
  loadingLazy?: boolean;
  isReserves?: boolean;
};
const props = defineProps<Props>();
defineEmits(["openProfileVideo", "openMatchVideo"]);

const cleanAverageFromMacros = (macros: Record<string, unknown>): Record<string, unknown> => {
  const _macros = JSON.parse(JSON.stringify(macros));
  delete _macros.average;
  return _macros;
};
</script>

<template>
  <div
    class="bg-gray-900 lg:rounded-lg px-4 py-5 kql-draft-player-card lg:min-w-[450px] overflow-hidden relative w-full lg:w-auto"
  >
    <div class="kql-clip-diagonal-wrapper">
      <div class="kql-clip-diagonal-right"></div>
      <div class="kql-clip-diagonal-bottom"></div>
    </div>
    <div class="flex gap-3.5 z-10 relative">
      <div
        class="relative bg-gray-300 overflow-hidden w-32 max-h-48 rounded"
        :class="{ 'flex justify-center': isReserves }"
      >
        <NuxtImg
          v-if="isReserves && player.draftOrigin"
          :src="setAliasUrl(player.imageUrl) || PlayerPlaceholder"
          :alt="player.name"
          :loading="loadingLazy ? 'lazy' : 'eager'"
          :class="[
            'kql-reserve-image',
            isReserves && player.draftOrigin && player.draftOrigin === 2023
              ? '!min-w-[130%]'
              : isReserves && player.draftOrigin && player.draftOrigin === 2024
                ? '!min-w-[154%]'
                : '',
          ]"
          width="452"
          height="672"
          sizes="128px"
          fit="outside"
        />
        <NuxtImg
          v-else
          :src="setAliasUrl(player.imageUrl) || PlayerPlaceholder"
          :alt="player.name"
          :loading="loadingLazy ? 'lazy' : 'eager'"
          class="w-32 rounded"
          width="452"
          height="672"
          sizes="128px"
        />

        <p v-if="player.draftJersey && !player.draftOrigin" class="kql-player-number">
          #<span v-if="player.draftJerseyPrefix">{{ player.draftJerseyPrefix }}</span
          >{{ player.draftJersey }}
        </p>
        <p v-else-if="player.draftJersey && !!player.draftOrigin" class="kql-player-number">
          <span v-if="player.draftOrigin">{{ player.draftOrigin }}</span> - #{{ player.draftJersey }}
        </p>
      </div>
      <div class="flex flex-col justify-between flex-1">
        <div class="flex justify-between">
          <div class="kql-player-name">
            <h3 class="font-bold">{{ player.name }}</h3>
            <p class="text-gray-400 text-xs lg:text-sm">
              {{ $t(`draft.player.position.${player.role}`) }}
            </p>
          </div>

          <div
            class="text-center bg-primary rounded-lg text-black p-1 min-w-[45px]"
            v-if="player.macros && player.macros.average"
          >
            <p class="text-[10px]">{{ $t("draft.player.average") }}</p>
            <p class="font-bold">{{ player.macros.average || "-" }}</p>
          </div>
        </div>
        <div :class="[{ 'mb-auto mt-8': !player.foot && !player.height }, 'flex flex-wrap max-w-48 gap-1']">
          <div v-if="player.draftOrigin" class="kql-chip-generic-info">
            <p class="info-title">Draft</p>
            <p class="info-text" v-text="player.draftOrigin"></p>
          </div>
          <div class="kql-chip-generic-info">
            <p
              class="info-title"
              v-text="!!player.birthdate ? $t('draft.player.birthdate') : $t('draft.player.age')"
            ></p>
            <p class="info-text" v-text="!!player.birthdate ? player.birthdate : player.age"></p>
          </div>
          <div v-if="player.foot" class="kql-chip-generic-info">
            <p class="info-title">{{ $t("draft.player.foot") }}</p>
            <p class="info-text">{{ $t(`draft.player.foot.${player.foot}`) }}</p>
          </div>
          <div v-if="player.height" class="kql-chip-generic-info">
            <p class="info-title">{{ $t("draft.player.height") }}</p>
            <p class="info-text">{{ (player.height / 100).toFixed(2) }}m</p>
          </div>
        </div>
        <div class="border-t border-gray-700 hidden lg:block pt-3">
          <button
            v-if="(player.draftJersey && player.profileYtVideoId) || player.profileYtVideoId"
            class="px-3 text-gray-400 font-medium py-2 rounded-md bg-black text-xs inline-block mr-0.5"
            @click="$emit('openProfileVideo', player)"
            data-modal-target="modal-video-draft"
            data-modal-show="modal-video-draft"
          >
            <draft-youtube-logo></draft-youtube-logo>
            {{ $t("draft.player.video.title") }}
          </button>
          <button
            v-if="player.draftJersey && player.matchYtVideoId"
            class="px-3 text-gray-400 font-medium py-2 rounded-md bg-black text-xs inline-block ml-0.5"
            @click="$emit('openMatchVideo', player)"
            data-modal-target="modal-video-draft"
            data-modal-show="modal-video-draft"
          >
            <draft-youtube-logo></draft-youtube-logo>
            {{ $t("draft.player.matchVideo.title") }}
          </button>
        </div>
      </div>
    </div>
    <div v-if="player.macros" class="flex gap-1 mt-2 relative">
      <div
        v-for="(value, key) in cleanAverageFromMacros(player.macros)"
        class="rounded-lg bg-gray-800 px-1 py-3 flex-1 text-center"
      >
        <p class="text-gray-400 text-[10px] kql-stats-key">
          <span v-if="key === 'passing' && player.role === 'PT'">{{
            $t("draft.player.goalkeeper.passing")
          }}</span>
          <span v-else-if="key === 'passing' && player.role !== 'PT'">{{ $t("draft.player.passing") }}</span>
          <span v-else>{{ $t(`draft.player.${key}`) }}</span>
        </p>
        <p class="font-bold">{{ value }}</p>
      </div>
    </div>
    <div class="lg:hidden pt-4 flex relative">
      <button
        v-if="(player.draftJersey && player.profileYtVideoId) || player.profileYtVideoId"
        class="kql-youtube-mbl-button mr-0.5"
        @click="$emit('openProfileVideo', player)"
        data-modal-target="modal-video-draft"
        data-modal-show="modal-video-draft"
      >
        <draft-youtube-logo></draft-youtube-logo>
        {{ $t("draft.player.video.title") }}
      </button>
      <button
        v-if="player?.draftJersey && player?.matchYtVideoId"
        class="kql-youtube-mbl-button ml-0.5"
        @click="$emit('openMatchVideo', player)"
        data-modal-target="modal-video-draft"
        data-modal-show="modal-video-draft"
      >
        <draft-youtube-logo></draft-youtube-logo>
        {{ $t("draft.player.matchVideo.title") }}
      </button>
    </div>
  </div>
</template>

<style scoped>
.kql-player-number {
  @apply text-sm lg:text-base font-bold px-2 py-1 bg-gray-800 rounded-full bg-opacity-60 absolute bottom-2 right-2 z-[3] lg:w-auto lg:h-auto opacity-100 transition-all ease-in duration-500;
}
.kql-draft-player-card:hover .kql-player-number {
  @apply opacity-0 cursor-default;
}

.kql-clip-diagonal-right {
  @apply block w-40 h-80 lg:w-24 lg:h-52 absolute left-0 top-0 transition-all ease-in-out duration-500 bg-[#242424];
  clip-path: polygon(0 0, 100% 0%, 0% 100%, 0 100%);
  background-image: none;
}
@media (min-width: 1200px) {
  .kql-clip-diagonal-right {
    background-image: linear-gradient(180deg, #3d3935 0%, #3d393520 100%);
    background-color: transparent;
  }
}
.kql-clip-diagonal-bottom {
  @apply block lg:hidden w-24 h-40 absolute right-0 bottom-0 transition-all ease-in-out duration-500 bg-[#242424];
  clip-path: polygon(100% 100%, 100% 0%, 0% 100%, 0 100%);
}
.kql-draft-player-card:hover .kql-clip-diagonal-right {
  @apply w-28 h-56;
}
.kql-player-name {
  @apply transition-all ease-in-out duration-500;
}
.kql-draft-player-card:hover .kql-player-name {
  @apply pl-2;
}
.kql-youtube-mbl-button {
  @apply px-3 text-gray-400 font-medium py-2 rounded-full border border-gray-700 text-xs flex-1 text-center flex items-center justify-center;
}

.kql-chip-generic-info {
  @apply px-1.5 py-1 rounded-lg bg-gray-800 flex items-center gap-x-2 w-fit;
}

.kql-chip-generic-info .info-title {
  @apply text-gray-400 text-[10px];
}

.kql-chip-generic-info .info-text {
  @apply text-xs font-bold;
}

.kql-reserve-image {
  @apply object-cover object-top;
}
</style>
