// src/draft/infrastructure/ui-services/draftUiService.ts

import type { AnyDraftPlayer } from "~/src/draft/domain/models/DraftPlayer";
import type { WebContext } from "~/src/Shared/Types";
import type { DraftYearFilter } from "~/src/draft/domain/models/Filters";

interface DraftUiService {
  getDraftTryoutsData: (
    year: number,
    region: string,
    webContext: WebContext,
    playerType: "goalkeeper" | "player"
  ) => Promise<AnyDraftPlayer[] | undefined>;
  getDraftReservesData: (
    year: number,
    region: string,
    webContext: WebContext,
    playerType: "goalkeeper" | "player"
  ) => Promise<AnyDraftPlayer[] | undefined>;
  generateDraftTryoutsOptions: (region: string, webContext: WebContext) => Promise<DraftYearFilter[]>;
  generateDraftReservesOptions: (region: string, webContext: WebContext) => Promise<DraftYearFilter[]>;
}

const getDraftTryoutsData = async (
  year: number,
  region: string,
  webContext: WebContext,
  playerType: "goalkeeper" | "player"
): Promise<AnyDraftPlayer[] | undefined> => {
  const players = import(`~/src/draft/datasources/${webContext}/${region}/${year}/${playerType}.json`)
    .then(data => {
      return data.default;
    })
    .catch(() => {
      return undefined;
    });

  if (players) {
    return await players;
  } else {
    console.error(`Error getting draft for: /${webContext}/${region}/${year}/${playerType}`);
    return undefined;
  }
};

const getDraftReservesData = async (
  year: number,
  region: string,
  webContext: WebContext,
  playerType: "goalkeeper" | "player"
): Promise<AnyDraftPlayer[] | undefined> => {
  const players = import(
    `~/src/draft/datasources/${webContext}/${region}/${year}/reserves/${playerType}.json`
  )
    .then(data => {
      return data.default;
    })
    .catch(() => {
      return undefined;
    });

  if (players) {
    return await players;
  } else {
    console.error(`Error getting reserves for: /${webContext}/${region}/${year}/${playerType}`);
    return undefined;
  }
};

const generateDraftTryoutsOptions = async (
  region: string,
  webContext: WebContext
): Promise<DraftYearFilter[]> => {
  const drafts: DraftYearFilter[] = [];

  const importPromises = [
    import(`~/src/draft/datasources/${webContext}/${region}/22/player.json`)
      .then(data => {
        if (data.default) {
          drafts.push({
            id: 1,
            year: 22,
            name: "2022",
          });
        }
      })
      .catch(() => undefined),

    import(`~/src/draft/datasources/${webContext}/${region}/23/player.json`)
      .then(data => {
        if (data.default) {
          drafts.push({
            id: 2,
            year: 23,
            name: "2023",
          });
        }
      })
      .catch(() => undefined),

    import(`~/src/draft/datasources/${webContext}/${region}/24/player.json`)
      .then(data => {
        if (data.default) {
          drafts.push({
            id: 3,
            year: 24,
            name: "2024",
          });
        }
      })
      .catch(() => undefined),
  ];

  await Promise.all(importPromises);

  return drafts.sort((a, b) => b.year - a.year);
};

const generateDraftReservesOptions = async (
  region: string,
  webContext: WebContext
): Promise<DraftYearFilter[]> => {
  const drafts: DraftYearFilter[] = [];

  const importPromises = [
    import(`~/src/draft/datasources/${webContext}/${region}/22/reserves/player.json`)
      .then(data => {
        if (data.default) {
          drafts.push({
            id: 1,
            year: 22,
            name: "2022",
          });
        }
      })
      .catch(() => undefined),

    import(`~/src/draft/datasources/${webContext}/${region}/23/reserves/player.json`)
      .then(data => {
        if (data.default) {
          drafts.push({
            id: 2,
            year: 23,
            name: "2023",
          });
        }
      })
      .catch(() => undefined),

    import(`~/src/draft/datasources/${webContext}/${region}/24/reserves/player.json`)
      .then(data => {
        if (data.default) {
          drafts.push({
            id: 3,
            year: 24,
            name: "2024",
          });
        }
      })
      .catch(() => undefined),
  ];

  await Promise.all(importPromises);

  return drafts.sort((a, b) => b.year - a.year);
};

const draftUiService = (): DraftUiService => ({
  getDraftTryoutsData,
  getDraftReservesData,
  generateDraftTryoutsOptions,
  generateDraftReservesOptions,
});

export default draftUiService;
